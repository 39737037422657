import React from 'react';
import './card.css';

export const Card = ({ dataExportPDF }) => {
  console.log('Hello:::', { dataExportPDF });

  return (
    // <div className='content-boxed'>
    //   <div className='content-item bgc-item'></div>
    //   <div className='content-item'></div>
    //   <div className='content-item bgc-item'></div>
    //   <div className='content-item'></div>
    //   <div className='content-item bgc-item'></div>
    //   <div className='content-item'></div>
    //   <div className='content-item bgc-item'></div>
    //   <div className='content-item'></div>
    // </div>
    // New New

    // ====       1    =====================
    <>
      <div style={{ border: '2px solid blue', marginLeft: '20px', width: '50%' }}>
        <div style={{ paddingLeft: '10px' }}>Cty</div>
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <b>PHIẾU CẤP NHIÊN LIỆU</b>
          <div>Số: displayNumberCode </div>
        </div>

        <div>
          <div style={{ padding: '0px 0px  0px 30px', margin: '0px' }}>
            <span>- Kính Gửi: Cửa Hàng Xăng Dầu</span>
            <div>- Chúng Tôi Đề Nghị Cửa Hàng Cấp Cho:</div>
          </div>

          <div style={{ border: '2px solid red', margin: '0px 10px', paddingLeft: '10px' }}>
            <span>- Lái Xe: </span>
            <b>driverMain</b>
            <br />
            <span>- Số Xe: </span>
            <b>Code</b>
            <br />
            <span>- Lý Do: </span>
            <b>Chi Dầu Ngày 20/11/2024</b>
            <br />
            <span>- Số Lượng: </span>
            <b>0</b> Lít <span>(bao gồm 5 Lít hỗ trợ)</span>
            <br />
            <span>- Bằng Chữ: </span>
            <b>1234,44,55</b>
            <br />
            <span>- Ngày Cấp: </span>
            <b>20/10/2024</b>
            <br />
            <b>Phiếu có giá trị trong 3 ngày kể từ ngày cấp.</b>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ marginLeft: 'auto', paddingRight: '10px' }}>Ngày Tháng Năm</div>
          </div>
          <div
            style={{
              display: 'flex',
              textAlign: 'center',
              alignItems: 'center',
              marginBottom: '33px',
            }}
          >
            <div style={{ width: '32%', fontWeight: 'bold' }}>Duyệt</div>
            <div style={{ width: '32%', fontWeight: 'bold' }}>Lập phiếu</div>
            <div style={{ width: '32%', fontWeight: 'bold' }}>Lái Xe</div>
          </div>
        </div>
      </div>

      {/* ====       1    ===================== */}
      <div style={{ border: '2px solid blue', marginLeft: '20px', width: '50%' }}>
        <div style={{ paddingLeft: '10px' }}>Cty</div>
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <b>PHIẾU CẤP NHIÊN LIỆU</b>
          <div>Số: displayNumberCode </div>
        </div>

        <div>
          <div style={{ padding: '0px 0px  0px 30px', margin: '0px' }}>
            <span>- Kính Gửi: Cửa Hàng Xăng Dầu</span>
            <div>- Chúng Tôi Đề Nghị Cửa Hàng Cấp Cho:</div>
          </div>

          <div style={{ border: '2px solid red', margin: '0px 10px', paddingLeft: '10px' }}>
            <span>- Lái Xe: </span>
            <b>driverMain</b>
            <br />
            <span>- Số Xe: </span>
            <b>Code</b>
            <br />
            <span>- Lý Do: </span>
            <b>Chi Dầu Ngày 20/11/2024</b>
            <br />
            <span>- Số Lượng: </span>
            <b>0</b> Lít <span>(bao gồm 5 Lít hỗ trợ)</span>
            <br />
            <span>- Bằng Chữ: </span>
            <b>1234,44,55</b>
            <br />
            <span>- Ngày Cấp: </span>
            <b>20/10/2024</b>
            <br />
            <b>Phiếu có giá trị trong 3 ngày kể từ ngày cấp.</b>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ marginLeft: 'auto', paddingRight: '10px' }}>Ngày Tháng Năm</div>
          </div>
          <div
            style={{
              display: 'flex',
              textAlign: 'center',
              alignItems: 'center',
              marginBottom: '33px',
            }}
          >
            <div style={{ width: '32%', fontWeight: 'bold' }}>Duyệt</div>
            <div style={{ width: '32%', fontWeight: 'bold' }}>Lập phiếu</div>
            <div style={{ width: '32%', fontWeight: 'bold' }}>Lái Xe</div>
          </div>
        </div>
      </div>
      {/* ====       1    ===================== */}
      <div style={{ border: '2px solid blue', marginLeft: '20px', width: '50%' }}>
        <div style={{ paddingLeft: '10px' }}>Cty</div>
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <b>PHIẾU CẤP NHIÊN LIỆU</b>
          <div>Số: displayNumberCode </div>
        </div>

        <div>
          <div style={{ padding: '0px 0px  0px 30px', margin: '0px' }}>
            <span>- Kính Gửi: Cửa Hàng Xăng Dầu</span>
            <div>- Chúng Tôi Đề Nghị Cửa Hàng Cấp Cho:</div>
          </div>

          <div style={{ border: '2px solid red', margin: '0px 10px', paddingLeft: '10px' }}>
            <span>- Lái Xe: </span>
            <b>driverMain</b>
            <br />
            <span>- Số Xe: </span>
            <b>Code</b>
            <br />
            <span>- Lý Do: </span>
            <b>Chi Dầu Ngày 20/11/2024</b>
            <br />
            <span>- Số Lượng: </span>
            <b>0</b> Lít <span>(bao gồm 5 Lít hỗ trợ)</span>
            <br />
            <span>- Bằng Chữ: </span>
            <b>1234,44,55</b>
            <br />
            <span>- Ngày Cấp: </span>
            <b>20/10/2024</b>
            <br />
            <b>Phiếu có giá trị trong 3 ngày kể từ ngày cấp.</b>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ marginLeft: 'auto', paddingRight: '10px' }}>Ngày Tháng Năm</div>
          </div>
          <div
            style={{
              display: 'flex',
              textAlign: 'center',
              alignItems: 'center',
              marginBottom: '33px',
            }}
          >
            <div style={{ width: '32%', fontWeight: 'bold' }}>Duyệt</div>
            <div style={{ width: '32%', fontWeight: 'bold' }}>Lập phiếu</div>
            <div style={{ width: '32%', fontWeight: 'bold' }}>Lái Xe</div>
          </div>
        </div>
      </div>
      {/* ====       1    ===================== */}
      <div style={{ border: '2px solid blue', marginLeft: '20px', width: '50%' }}>
        <div style={{ paddingLeft: '10px' }}>Cty</div>
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <b>PHIẾU CẤP NHIÊN LIỆU</b>
          <div>Số: displayNumberCode </div>
        </div>

        <div>
          <div style={{ padding: '0px 0px  0px 30px', margin: '0px' }}>
            <span>- Kính Gửi: Cửa Hàng Xăng Dầu</span>
            <div>- Chúng Tôi Đề Nghị Cửa Hàng Cấp Cho:</div>
          </div>

          <div style={{ border: '2px solid red', margin: '0px 10px', paddingLeft: '10px' }}>
            <span>- Lái Xe: </span>
            <b>driverMain</b>
            <br />
            <span>- Số Xe: </span>
            <b>Code</b>
            <br />
            <span>- Lý Do: </span>
            <b>Chi Dầu Ngày 20/11/2024</b>
            <br />
            <span>- Số Lượng: </span>
            <b>0</b> Lít <span>(bao gồm 5 Lít hỗ trợ)</span>
            <br />
            <span>- Bằng Chữ: </span>
            <b>1234,44,55</b>
            <br />
            <span>- Ngày Cấp: </span>
            <b>20/10/2024</b>
            <br />
            <b>Phiếu có giá trị trong 3 ngày kể từ ngày cấp.</b>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ marginLeft: 'auto', paddingRight: '10px' }}>Ngày Tháng Năm</div>
          </div>
          <div
            style={{
              display: 'flex',
              textAlign: 'center',
              alignItems: 'center',
              marginBottom: '33px',
            }}
          >
            <div style={{ width: '32%', fontWeight: 'bold' }}>Duyệt</div>
            <div style={{ width: '32%', fontWeight: 'bold' }}>Lập phiếu</div>
            <div style={{ width: '32%', fontWeight: 'bold' }}>Lái Xe</div>
          </div>
        </div>
      </div>
    </>
    // Old
    // <div className='card-container'>
    //   <h2 className='card-title'>Duong</h2>
    //   <p className='card-paragraph'>
    //     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget libero quam. Fusce
    //     efficitur, lectus ac commodo maximus, neque augue tincidunt tellus, id dictum odio eros ac
    //     nulla.
    //   </p>
    //   <p className='card-paragraph'>
    //     Vivamus at urna sit amet justo auctor vestibulum ut nec nisl. Sed auctor augue eget libero
    //     tincidunt, ut dictum libero facilisis. Phasellus non libero at nisi eleifend tincidunt a
    //     eget ligula.
    //   </p>
    // </div>
  );
};

import React, { useEffect, forwardRef, useImperativeHandle, useState, useRef } from 'react';
import { Form, Input, DatePicker, Select, Divider, Row, Col, Checkbox } from 'antd';
import ApiServices from '~services';
import storage from '~storage';
import { Selection } from '~components';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '~commons/constants';
import utils from '~commons/utils';
import moment from 'moment';
// import { useExistsCodeValidator } from '~hooks';
import { UploadFileNew } from '~components/upload-file-new';
const { Option } = Select;

export const BillForm = forwardRef(
  (
    {
      fields,
      onChange,
      onsubmit,
      orderDetail,
      isNew = false,
      showDetail = false,
      dataOrderDetail = null,
    },
    ref
  ) => {
    const inputRef = useRef(null);
    const [locations, setLocations] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [shippingLines, setShippingLines] = useState([]);
    const [cargoTypes, setCargoTypes] = useState([]);

    const [form] = Form.useForm();

    // const checkExistOrderApi = (val) =>
    //   !isNew ? Promise.resolve(false) : ApiServices.checkOrderExistCode(val);
    // const existsCodeValidator = useExistsCodeValidator(checkExistOrderApi, 'Mã Bil đã tồn tại!');

    const onCompareWithApi = (value, cb) => {
      if (inputRef.current) {
        clearTimeout(inputRef.current);
        cb();
      }
      inputRef.current = setTimeout(async () => {
        try {
          const { data } = await ApiServices.checkOrderExistCode(value);
          const isUsed = data;
          cb(isUsed);
        } catch (error) {
          console.error(error);
        }
      }, 1000);
    };

    const checkExistsCode = async (_, valueInput) => {
      const value = valueInput.trim();
      if (value.length > 2) {
        if (isNew === false) {
          if (dataOrderDetail) {
            const ind = fields.findIndex((item) => {
              return item.name[0] === 'code';
            });
            if (value !== fields[ind]?.value) {
              try {
                const waitMe = () =>
                  new Promise((resolve, reject) => {
                    onCompareWithApi(value, (isUsed) => {
                      if (typeof isUsed !== 'undefined') {
                        if (isUsed) reject();
                        resolve();
                      }
                    });
                  });
                await waitMe();
              } catch (error) {
                return Promise.reject(new Error('Mã Số Bill này đã tạo rồi !!!'));
              }
            }
          }
          if (orderDetail) {
            if (value !== orderDetail?.code) {
              try {
                const waitMe = () =>
                  new Promise((resolve, reject) => {
                    onCompareWithApi(value, (isUsed) => {
                      if (typeof isUsed !== 'undefined') {
                        if (isUsed) reject();
                        resolve();
                      }
                    });
                  });
                await waitMe();
              } catch (error) {
                return Promise.reject(new Error('Mã Số Bill này đã tạo rồi !!!'));
              }
            }
          }
        }

        if (isNew === true) {
          try {
            const waitMe = () =>
              new Promise((resolve, reject) => {
                onCompareWithApi(value, (isUsed) => {
                  if (typeof isUsed !== 'undefined') {
                    if (isUsed) reject();
                    resolve();
                  }
                });
              });
            await waitMe();
          } catch (error) {
            return Promise.reject(new Error('Mã Số Bill này đã tạo rồi !!!'));
          }
        }
      }
    };

    useImperativeHandle(ref, () => ({
      submitForm() {
        form.submit();
      },
    }));

    const getCustomers = async (query = null) => {
      try {
        const { data } = await ApiServices.getCustomers({ query });
        setCustomers(data);
      } catch (err) {
        console.error('ERR - Get Customers', err);
      }
    };
    const getShippingLines = async (Query = null) => {
      try {
        const { data } = await ApiServices.getShippingLines({ Query });
        setShippingLines(data.data);
      } catch (err) {
        console.error('ERR - Get Shipping Lines', err);
      }
    };
    const getLocations = async (Query = null) => {
      try {
        const { data } = await ApiServices.getLocations({ Query });
        setLocations(data.data);
      } catch (err) {
        console.error('ERR - Get Shipping Lines', err);
      }
    };

    const getLocationsDetail = async (id) => {
      try {
        const { data } = await ApiServices.getDetailsLocation(id);
        setLocations(data.data);
      } catch (err) {
        console.error('ERR - Get Shipping Lines', err);
      }
    };

    const getLocationsGetInit = () => {
      if (fields) {
        const ind = fields.findIndex((item) => item.name[0] === 'locationDepotReturnCode');
        if (fields[ind].value) {
          const ID = fields[ind].value;
          getLocationsDetail(ID);
        }
      }
    };

    const getCargoTypes = async (Query = null) => {
      try {
        const { data } = await ApiServices.getCargoTypes({ Query });
        if (data?.data) {
          const dataFind = data?.data?.find((item) => item?.id === '60b4b48fef42f1213378e624');
          if (dataFind) {
            const dataFilter = data?.data?.filter((el) => el?.id !== '60b4b48fef42f1213378e624');
            dataFilter.unshift(dataFind);
            setCargoTypes(dataFilter || []);
          } else {
            setCargoTypes(data?.data);
          }
        }
      } catch (err) {
        console.error('ERR - Get Shipping Lines', err);
      }
    };

    useEffect(() => {
      getCustomers();
      getShippingLines();
      getLocations();
      getLocationsGetInit();
      getCargoTypes();
    }, []);

    const onSubmitForm = async () => {
      onsubmit();
    };
    const transportType = storage.staticVariables.TransportType;
    const transportTypeDiv = (
      <Select placeholder='Loại vận chuyển' showSearch>
        {Object.keys(transportType).map((item) => (
          <Option key={item} value={transportType[item]}>
            {transportType[item]}
          </Option>
        ))}
      </Select>
    );

    return (
      <Form
        layout='vertical'
        className='form-booking'
        form={form}
        name='global_state'
        onFinish={onSubmitForm}
        fields={fields}
        onFieldsChange={(_, allFields) => {
          onChange(allFields);
        }}
      >
        <h2 style={{ marginTop: '-10px' }}>
          {orderDetail ? (showDetail ? 'Xem chi tiết' : 'Cập nhật ') : 'Tạo '} D/O
        </h2>
        <Divider style={{ margin: '4px 0 8px 0' }} />
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name='billNumber'
              label='Số Bill'
              rules={[
                {
                  required: true,
                  message: 'Bạn phải nhập Số Bill',
                },
                {
                  validator: checkExistsCode,
                },
              ]}
            >
              <Input placeholder='Số Bill' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='transportType' label='Loại Vận Chuyển'>
              {transportTypeDiv}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='customerCode'
              label='Khách hàng'
              rules={[
                {
                  required: true,
                  message: 'Bạn phải nhập Mã Khách hàng',
                },
              ]}
            >
              <Selection
                data={customers}
                labelDefault={
                  dataOrderDetail ? dataOrderDetail?.customerName : orderDetail?.customerName
                }
                placeholder='Mã Khách hàng'
                onSearch={getCustomers}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='shippingLineCode'
              label='Hãng tàu'
              rules={[
                {
                  required: true,
                  message: 'Bạn phải nhập Hãng tàu',
                },
              ]}
            >
              <Selection
                data={shippingLines}
                labelDefault={
                  dataOrderDetail
                    ? dataOrderDetail?.shippingLineName
                    : orderDetail?.shippingLineName
                }
                placeholder='Mã Hãng tàu'
                onSearch={getShippingLines}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='atd' label='Ngày Tàu Đến'>
              <DatePicker
                placeholder='Ngày Tàu Đến'
                style={{ width: '100%' }}
                // disabledDate={(current) => utils.disabledDate(current, null, moment())}
                format={DATE_FORMAT}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            {/* <Form.Item
        name="deliveryContRequestDate"
        label="Ngày Yêu Cầu Lấy Công"
        rules={[
          {
            required: true,
            message: 'Bạn phải nhập Ngày Yêu Cầu Lấy Công',
          },
          ...utils.validateDatePicker(
            'Ngày tàu đến',
            null,
            // 'Ngày Hết Hạn Lưu Cont', 
            form.getFieldValue().atd
          ),
        ]}
      >
        <DatePicker
          placeholder="Ngày Yêu Cầu Lấy Cont"
          style={{ width: '100%' }}
          format={DATE_FORMAT}
        />
        </Form.Item>
        </Col>
        <Col span={12}> */}

            <Form.Item
              name='lastFreeDayDEM'
              label='Ngày Hết Hạn Lưu Cont'
              // rules={[
              //   {
              //     required: true,
              //     message: 'Bạn phải nhập Ngày Hết Hạn Lưu Cont',
              //   },
              // ]}
            >
              <DatePicker
                showTime={{ defaultValue: moment('00:00', 'HH:mm'), format: 'HH:mm' }}
                disabledTime={utils.disabledTime}
                placeholder='Ngày Hết Hạn Lưu Cont'
                style={{ width: '100%' }}
                format={DATE_TIME_FORMAT}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='lastFreeDayDET'
              label='Ngày Hết Hạn Trả Rỗng'
              // rules={[
              //   {
              //     required: true,
              //     message: 'Bạn phải nhập Ngày Hết Hạn Trả Rỗng',
              //   },
              //   // ...utils.validateDatePicker(
              //   //   'Ngày Hết Hạn Lưu Cont',
              //   //   null,
              //   //   form.getFieldValue().LastFreeDayDEM
              //   // ),
              // ]}
            >
              <DatePicker
                showTime={{ format: 'HH:mm' }}
                placeholder='Ngày Hết Hạn Trả Rỗng'
                style={{ width: '100%' }}
                format={DATE_TIME_FORMAT}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name='cargoTypeCode' label='Loại Hàng Hóa'>
              <Selection
                data={cargoTypes}
                labelDefault={
                  dataOrderDetail ? dataOrderDetail?.cargoTypeName : orderDetail?.cargoTypeName
                }
                placeholder='Loại Hàng Hóa'
                fieldValue='name'
                onSearch={getCargoTypes}
                autoSelectFirstOption
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name='locationDepotReturnCode' label='Nơi Trả Rỗng'>
              <Selection
                labelDefault={
                  dataOrderDetail
                    ? dataOrderDetail?.locationDepotReturnName
                    : orderDetail?.locationDepotReturnName
                }
                data={locations}
                placeholder='Nơi Trả Rỗng'
                onSearch={getLocations}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='locationOfReceiptCode'
              label='Cảng lấy hàng'
              rules={[
                {
                  required: true,
                  message: 'Bạn phải nhập Cảng lấy hàng',
                },
              ]}
            >
              <Selection
                data={locations}
                labelDefault={
                  dataOrderDetail
                    ? dataOrderDetail?.locationOfReciptName
                    : orderDetail?.locationOfReciptName
                }
                placeholder='Nơi Lấy Hàng'
                fieldValue='name'
                onSearch={getLocations}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='locationOfDeliveryCode'
              label='Nơi Giao Hàng'
              rules={[
                {
                  required: true,
                  message: 'Bạn phải nhập Nơi Giao Hàng',
                },
              ]}
            >
              <Selection
                labelDefault={
                  dataOrderDetail
                    ? dataOrderDetail?.locationOfDeliveryName
                    : orderDetail?.locationOfDeliveryName
                }
                data={locations}
                placeholder='Nơi Giao Hàng'
                fieldValue='name'
                onSearch={getLocations}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='vesselAndVoyage' label='Tên Chuyến Tàu'>
              <Input placeholder='Tên Chuyến Tàu' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='note' label='Ghi chú'>
              <Input.TextArea placeholder='Ghi chú' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='orderFiles' label='File Đính Kèm'>
              <UploadFileNew />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='bookReturn' valuePropName='checked' label=''>
              <Checkbox disabled={!isNew}>
                <h3
                  style={{
                    color: 'blue',
                  }}
                >
                  Hàng xuất kéo về
                </h3>
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
);

import React from 'react';

const CountCont = ({ countContBook, countContBill }) => {
  return (
    <>
      <table
        style={{
          border: '1px solid blue',
          borderCollapse: 'collapse',
          marginRight: '50px',
        }}
      >
        <tr>
          <th
            style={{
              border: '1px solid blue',
              borderCollapse: 'collapse',
              padding: '10px',
              backgroundColor: '#FFE6A9',
            }}
          >
            Loại Cont
          </th>
          <th
            style={{
              border: '1px solid blue',
              borderCollapse: 'collapse',
              padding: '10px',
            }}
          >
            40C
          </th>
          <th
            style={{
              border: '1px solid blue',
              borderCollapse: 'collapse',
              padding: '10px',
            }}
          >
            20
          </th>
          <th
            style={{
              border: '1px solid blue',
              borderCollapse: 'collapse',
              padding: '10px',
            }}
          >
            40T
          </th>
          <th
            style={{
              border: '1px solid blue',
              borderCollapse: 'collapse',
              padding: '10px',
            }}
          >
            45
          </th>
        </tr>
        {/* Body */}
        {/* Booking */}
        <tr>
          <td
            style={{
              border: '1px solid blue',
              borderCollapse: 'collapse',
              padding: '10px',
              fontWeight: 'bold',
              backgroundColor: '#F2F9FF',
            }}
          >
            Xuất (Booking)
          </td>
          <td
            style={{
              border: '1px solid blue',
              borderCollapse: 'collapse',
              padding: '10px',
              backgroundColor: '#F2F9FF',
            }}
          >
            {countContBook?.cont40CBook || 0}
          </td>
          <td
            style={{
              border: '1px solid blue',
              borderCollapse: 'collapse',
              padding: '10px',
              backgroundColor: '#F2F9FF',
            }}
          >
            {countContBook?.cont20Book || 0}
          </td>
          <td
            style={{
              border: '1px solid blue',
              borderCollapse: 'collapse',
              padding: '10px',
              backgroundColor: '#F2F9FF',
            }}
          >
            {countContBook?.cont40TBook || 0}
          </td>
          <td
            style={{
              border: '1px solid blue',
              borderCollapse: 'collapse',
              padding: '10px',
              backgroundColor: '#F2F9FF',
            }}
          >
            {countContBook?.cont45Book || 0}
          </td>
        </tr>
        {/* Bill */}
        <tr>
          <td
            style={{
              border: '1px solid blue',
              borderCollapse: 'collapse',
              padding: '10px',
              fontWeight: 'bold',
              backgroundColor: 'rgb(234, 238, 243)',
              color: 'blue',
            }}
          >
            Nhập D/O
          </td>
          <td
            style={{
              border: '1px solid blue',
              borderCollapse: 'collapse',
              padding: '10px',
              backgroundColor: 'rgb(234, 238, 243)',
              color: 'blue',
            }}
          >
            {countContBill?.cont40CBill || 0}
          </td>
          <td
            style={{
              border: '1px solid blue',
              borderCollapse: 'collapse',
              padding: '10px',
              backgroundColor: 'rgb(234, 238, 243)',
              color: 'blue',
            }}
          >
            {countContBill?.cont20Bill || 0}
          </td>
          <td
            style={{
              border: '1px solid blue',
              borderCollapse: 'collapse',
              padding: '10px',
              backgroundColor: 'rgb(234, 238, 243)',
              color: 'blue',
            }}
          >
            {countContBill?.cont40TBill || 0}
          </td>
          <td
            style={{
              border: '1px solid blue',
              borderCollapse: 'collapse',
              padding: '10px',
              backgroundColor: 'rgb(234, 238, 243)',
              color: 'blue',
            }}
          >
            {countContBill?.cont45Bill || 0}
          </td>
        </tr>
      </table>
    </>
  );
};

export { CountCont };

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
const ExcelJS = require('exceljs');
import moment from 'moment';
import {
  Input,
  Button,
  Space,
  Modal,
  Checkbox,
  Badge,
  message,
  DatePicker,
  Divider,
  Popconfirm,
  Select,
} from 'antd';
import { DownloadOutlined, SwapOutlined, CheckOutlined } from '@ant-design/icons';
import { TableLayoutNew, RangeDateFilter, BtnConfirm, UploadExcelButton } from '~components';
import ApiServices from '~services';
import Utils from '~commons/utils';
import { TableTitle, Wrapper } from '~commons/styles';
import { TicketIsAdded } from './ticket-is-added';
import { FormUpdate } from './form-update';
import { FormChangeDissolveDate } from './form-change-dissolvedate';
import { DATE_FORMAT, DATE_FORMAT_SEND_API } from '~commons/constants';
import { CountCont } from './countCont';

const { Option } = Select;

const EXCEL_TICKET_JOB_TEMPLATE_URL =
  Utils.getAppUrl() + 'Files/Import_Excel/Giai_Tru_VanTai_TaiXe.xlsx';
const EXCEL_TEMPLATE_URL = Utils.getAppUrl() + 'Files/Import_Excel/Giai_Tru_VanTai_XeNgoai.xlsx';

const DEFAULT_QUERIES = {
  PageSize: 10,
  PageNumber: 1,
  DateFrom: moment().startOf('month').format(),
  DateTo: moment().endOf('date').format(),
};

const TICKET_OPTIONS_STATUSES = [
  {
    value: '',
    label: 'Tất cả',
  },
  {
    value: 0,
    label: 'Đã xác nhận',
  },
  {
    value: 1,
    label: 'Chưa xác nhận',
  },
];

const UpdateTicketJob = () => {
  const [loading, setLoading] = useState(false);
  const [loadingAccept, setLoadingAccept] = useState(false);

  const [selected, setSelected] = useState('');
  const [bookingList, setBookingList] = useState([]);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [dataRowSelected, setDataRowSelected] = useState(null);
  const [isCreateTicketList, setCreateTicketList] = useState([]);
  const [currentTicketSelected, setCurrentTicketSelected] = useState(null);
  const [selectedMultipleTicket, setSelectedMultipleTicket] = useState([]);
  const [selectedMultipleTicketRows, setSelectedMultipleTicketRows] = useState([]);
  const [showChangeDissolveData, setShowChangeDissolveData] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [assignDateSearch, setAssignDateSearch] = useState(null);
  const [dissolveDateSearch, setDissolveDateSearch] = useState(null);

  const [loadingExport, setLoadingExport] = useState(false);

  const [countContBook, setCountContBook] = useState({
    cont40CBook: 0,
    cont20Book: 0,
    cont40TBook: 0,
    cont45Book: 0,
  });
  const [countContBill, setCountContBill] = useState({
    cont40CBill: 0,
    cont20Bill: 0,
    cont40TBill: 0,
    cont45Bill: 0,
  });

  const countSelectedTicket = useMemo(() => {
    return selectedMultipleTicket?.length;
  }, [selectedMultipleTicket]);

  const countSelectedTicketToAccept = useMemo(() => {
    return selectedMultipleTicketRows?.filter((el) => el.status === 1)?.length;
  }, [selectedMultipleTicket]);

  const getData = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = {
      ...query,
      DateFrom: moment(query.DateFrom).format('YYYY-MM-DD'),
      DateTo: moment(query.DateTo).format('YYYY-MM-DD'),
    };

    return ApiServices.getTicketJobList(newQuery)
      .then((res) => {
        if (res?.data) {
          let cont40CBook = 0;
          let cont20Book = 0;
          let cont40TBook = 0;
          let cont45Book = 0;
          let cont40CBill = 0;
          let cont20Bill = 0;
          let cont40TBill = 0;
          let cont45Bill = 0;

          res?.data?.data?.forEach((element) => {
            switch (element?.contType) {
              case '40C':
                if (element?.orderType === 'Booking') {
                  cont40CBook++;
                }
                if (element?.orderType === 'Bill') {
                  cont40CBill++;
                }
                return;
              case '20"':
                if (element?.orderType === 'Booking') {
                  cont20Book++;
                }
                if (element?.orderType === 'Bill') {
                  cont20Bill++;
                }
                return;
              case '40T':
                if (element?.orderType === 'Booking') {
                  cont40TBook++;
                }
                if (element?.orderType === 'Bill') {
                  cont40TBill++;
                }
                return;
              case '45':
                if (element?.orderType === 'Booking') {
                  cont45Book++;
                }
                if (element?.orderType === 'Bill') {
                  cont45Bill++;
                }
                return;

              default:
                break;
            }
          });

          setCountContBook({
            cont40CBook,
            cont20Book,
            cont40TBook,
            cont45Book,
          });
          setCountContBill({
            cont40CBill,
            cont20Bill,
            cont40TBill,
            cont45Bill,
          });

          setBookingList(res?.data);
        }
      })
      .catch((err) => {
        console.error('get Report Oil Fuel Distribution Error', err);
      })
      .finally(() => setLoading(false));
  };

  const onColumnSearch = (event) => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = event.target.value;
      const name = event.target.name === 'aid' ? 'AID' : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };
  const onColumnSearchSelect = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value && value !== 0) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };
  const onColumnSearchDate = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };
  const handleChangeAssignDateSearch = (date) => {
    setAssignDateSearch(date);
    const dataSearch = date ? moment(date).format(DATE_FORMAT_SEND_API) : null;
    onColumnSearchDate(dataSearch, 'assignDate');
  };
  const handleChangeDissolveDateSearch = (date) => {
    setDissolveDateSearch(date);
    const dataSearch = date ? moment(date).format(DATE_FORMAT_SEND_API) : null;
    onColumnSearchDate(dataSearch, 'dissolveDate');
  };

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getData(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  const handleCancel = () => {
    setDataRowSelected(null);
    setCreateTicketList([]);
    setCurrentTicketSelected(null);
    timeoutGetData(filter);
  };

  const handleCancelChangeDissolveDate = () => {
    setShowChangeDissolveData(false);
  };

  const handlePrintMultipleTicket = () => {
    setShowChangeDissolveData(true);
  };

  const reloadChangeDissolveDate = () => {
    setSelectedMultipleTicket([]);
    setSelectedMultipleTicketRows([]);
    setShowChangeDissolveData(false);
    timeoutGetData(filter);
  };

  const reload = () => {
    return timeoutGetData(filter);
  };

  const handleAcceptTicket = async (dataRowSelectNew) => {
    const listIdValidToAccept = dataRowSelectNew
      ?.filter((el) => el?.status === 1)
      ?.map((item) => item.id);

    if (loadingAccept || listIdValidToAccept?.length < 0) return;
    setLoadingAccept(true);
    try {
      const res = await ApiServices.acceptMultipleTickets(listIdValidToAccept);
      if (res?.data?.data) {
        window.TmsNoti.success(`Xác nhận ${countSelectedTicketToAccept} phiếu thành công`);
        return reloadChangeDissolveDate();
      } else {
        window.TmsNoti.error('Lỗi', 'Xác nhận thất baị');
      }
    } catch (error) {
      console.log('acceptMultipleTickets:::', { error });
      window.TmsNoti.error('Lỗi', 'Xác nhận thất baị');
    } finally {
      setLoadingAccept(false);
    }
  };

  const onRowSelected = async (record) => {
    if (loading) return;

    setLoading(true);
    return ApiServices.getDetailTicket(record.id)
      .then((res) => {
        if (res?.data?.data) {
          const dataIsCreateTicket = res?.data?.data?.expenses?.filter((item) => {
            return item?.objTicketExpenses;
          });
          const differentTicket = dataIsCreateTicket?.filter(
            (item) => item?.objTicketExpenses?.ticketId !== record.id
          );
          const mapExpenses = differentTicket?.map((item) => {
            item.key = item.id;
            item.isExpenses = item?.objTicketExpenses ? true : false;
            item.price = item?.objTicketExpenses ? item?.objTicketExpenses?.price : '';
            item.vat = item?.objTicketExpenses ? item?.objTicketExpenses?.vat : '';
            item.total = item?.objTicketExpenses ? item?.objTicketExpenses?.total : '';
            item.billNumber = item?.objTicketExpenses ? item?.objTicketExpenses?.billNumber : '';
            item.billDate = item?.objTicketExpenses ? item?.objTicketExpenses?.billDate : null;
            item.supplierName = item?.objTicketExpenses
              ? item?.objTicketExpenses?.supplierName
              : '';

            return item;
          });
          const currentTicket = dataIsCreateTicket?.filter(
            (item) => item?.objTicketExpenses?.ticketId === record.id
          );
          setCreateTicketList(mapExpenses || []);
          setCurrentTicketSelected(currentTicket);
          setDataRowSelected(record);
        }
      })
      .catch((err) => {
        console.log({ err });
      })
      .finally(() => setLoading(false));
  };

  const handleDelete = (ticketId) => {
    if (loading) return;
    setLoading(true);
    ApiServices.deleteTicket(ticketId)
      .then((res) => {
        if (res.data) {
          reload();
          message.success('Xóa thành công');
        }
      })
      .catch((err) => {
        console.error('Delete failed: ', err);
        message.error('Xoá thất bại, Vui lòng thử lại sau!');
      })
      .finally(() => setLoading(false));
  };
  const handleDeleteFeeOfTicket = (ticketExpenseId) => {
    if (loadingDelete) return;
    setLoadingDelete(true);
    ApiServices.deleteFeeOfTicket(ticketExpenseId)
      .then((res) => {
        if (res.data) {
          message.success('Xóa thành công');
          return onRowSelected(dataRowSelected);
        }
      })
      .catch((err) => {
        console.error('Delete failed: ', err);
        message.error('Xoá thất bại, Vui lòng thử lại sau!');
      })
      .finally(() => setLoadingDelete(false));
  };

  const onDeleteFee = (data) => {
    // Delete Fee of Ticket
    if (currentTicketSelected?.length > 1) {
      return handleDeleteFeeOfTicket(data?.ticketExpenseId);
    }
    // Delete ticketId
    if (currentTicketSelected?.length === 1) {
      handleCancel();
      return handleDelete(data?.ticketId);
    }
  };

  const renderIsVendor = (value) => {
    if (value) {
      return <Checkbox checked />;
    }
    return '';
  };
  const renderContNumber = (contValue, record) => {
    return (
      <>
        <div>{contValue}</div>
        {record?.commission && (
          <i
            className='red'
            style={{
              fontSize: '11px',
            }}
          >
            Cont Ủy Quyền
          </i>
        )}
      </>
    );
  };

  const onConfirm = async (idTicket) => {
    if (loading || !idTicket) return;
    setLoading(true);
    try {
      const res = await ApiServices.toggleStatusTicket(idTicket);
      if (res?.data?.data === 'success') {
        window.TmsNoti?.success('Thay đổi thành công');
        return reload();
      }
    } catch (error) {
      window.TmsNoti?.error('Thay đổi thất bại');
      console.log(`Change Status Failed:::`, error);
    } finally {
      setLoading(false);
    }
  };

  const statusTag = (idTicket, status, text = '', bgColor = '', textSwapStatus = '') => {
    return (
      <Popconfirm
        placement='topRight'
        title={
          <span>
            Chuyển thành <b className='red'>{textSwapStatus}</b>?
          </span>
        }
        okText='Có'
        cancelText='Không'
        onConfirm={() => onConfirm(idTicket)}
        disabled={!idTicket || status !== 1}
      >
        <div
          style={{
            backgroundColor: bgColor,
            width: '100px',
            padding: '8px 10px',
            textAlign: 'center',
            borderRadius: '4px',
            color: 'white',
            // fontWeight: 'bold',
            fontSize: '11px',
            cursor: 'pointer',
          }}
        >
          {text}
        </div>
      </Popconfirm>
    );
  };
  const statusTagSucceed = (text = '', bgColor = '') => {
    return (
      <div
        style={{
          backgroundColor: bgColor,
          width: '100px',
          padding: '8px 10px',
          textAlign: 'center',
          borderRadius: '4px',
          color: 'white',
          fontSize: '11px',
        }}
      >
        {text}
      </div>
    );
  };
  const renderStatus = (status, idTicket) => {
    if (status === 0) {
      return statusTagSucceed('Đã xác nhận', 'green');
    } else {
      return statusTag(idTicket, status, 'Chờ xác nhận', '#EB455F', 'Xác nhận');
    }
  };

  const columns = [
    {
      title: (
        <TableTitle>
          Số phiếu
          <Input name='aid' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'aid',
      align: 'center',
      width: 100,
      render: (value, record) => (
        <a onClick={() => onRowSelected(record)}>{value || 'underFine'}</a>
      ),
    },
    {
      title: (
        <TableTitle>
          Folder
          <Input name='folder' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'folder',
      align: 'center',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Số Cont
          <Input name='contNumber' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'contNumber',
      align: 'center',
      width: 150,
      render: renderContNumber,
    },
    {
      title: (
        <TableTitle>
          Số SubNo
          <Input name='subnoNumber' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'subnoNumber',
      align: 'center',
      width: 200,
    },
    {
      title: (
        <TableTitle>
          Khách hàng
          <Input name='customerName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'customerName',
      align: 'center',
      width: 200,
    },
    {
      title: (
        <TableTitle>
          Ngày điều xe
          <DatePicker
            value={assignDateSearch}
            onChange={handleChangeAssignDateSearch}
            placeholder='Search'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'assignDate',
      align: 'center',
      width: 130,
      render: (val) => Utils.formatDate(val),
    },
    {
      title: 'Xe ngoài',
      dataIndex: 'isVendor',
      width: 100,
      align: 'center',
      render: renderIsVendor,
    },
    {
      title: (
        <TableTitle>
          Tài xế/Nhà xe
          <Input name='driverMainName' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'driverMainName',
      align: 'center',
      width: 200,
    },
    {
      title: (
        <TableTitle>
          Ngày giải trừ
          <DatePicker
            value={dissolveDateSearch}
            onChange={handleChangeDissolveDateSearch}
            placeholder='Search'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'dissolveDate',
      align: 'center',
      width: 130,
      render: (val) => Utils.formatDate(val),
    },
    {
      title: <TableTitle>Số tiền</TableTitle>,
      dataIndex: 'total',
      align: 'center',
      width: 150,
      render: (value) => Utils.formatNumber(value),
    },
    {
      title: (
        <TableTitle>
          Người giải trừ
          <Input name='createdBy' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'createdBy',
      align: 'center',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Ghi chú
          <Input name='note' onChange={onColumnSearch} placeholder='Search' />
        </TableTitle>
      ),
      dataIndex: 'note',
      align: 'center',
      width: 150,
    },
    {
      title: (
        <TableTitle>
          Trạng thái
          <Select
            placeholder='Search'
            defaultValue={''}
            style={{ width: 100, fontSize: '11px' }}
            onChange={(status) => onColumnSearchSelect(status, 'status')}
          >
            {TICKET_OPTIONS_STATUSES.map((item) => (
              <Option key={item.label + item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </TableTitle>
      ),
      dataIndex: 'status',
      align: 'center',
      fixed: 'right',
      width: 120,
      render: (val, record) => renderStatus(val, record?.id),
    },
    {
      title: 'Action',
      fixed: 'right',
      width: 75,
      render: (record) => (
        <BtnConfirm
          onConfirm={() => {
            handleDelete(record?.id);
          }}
        >
          Xóa
        </BtnConfirm>
      ),
    },
  ];

  const pagination = {
    total: bookingList?.totalRecords || 0,
    showSizeChanger: true,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber, PageSize) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
        PageSize,
      })),
  };

  const renderError = (item) => {
    return <div>{item}</div>;
  };
  const onUploadTicketJobExcel = (file) => {
    if (loading) return;
    setLoading(true);
    ApiServices.importExcelForJobTicket(file)
      .then((res) => {
        if (res?.data?.data) {
          if (res?.data?.errors?.length > 0) {
            window.TmsNoti.error(res?.data?.message, res?.data?.errors?.map(renderError), 0);
          } else {
            window.TmsNoti.success(res?.data?.message);
            timeoutGetData(filter);
          }
        }
      })
      .catch((err) => {
        console.error('UPLOAD IMPORT ERROR', err);
        message.error('Thao tác thất bại, Vui lòng thử lại sau!');
      })
      .finally(() => setLoading(false));
  };

  const onUploadExcel = (file) => {
    if (loading) return;
    setLoading(true);
    ApiServices.importExcelForJobVendorTicket(file)
      .then((res) => {
        // if (res?.data?.data) {
        //   message.success(res?.data?.data);
        //   timeoutGetData(filter);
        // }
        if (res?.data?.data) {
          if (res?.data?.errors?.length > 0) {
            window.TmsNoti.error(res?.data?.message, res?.data?.errors?.map(renderError), 0);
          } else {
            window.TmsNoti.success(res?.data?.message);
            timeoutGetData(filter);
          }
        }
      })
      .catch((err) => {
        console.error('UPLOAD IMPORT ERROR', err);
        message.error('Thao tác thất bại, Vui lòng thử lại sau!');
      })
      .finally(() => setLoading(false));
  };

  const handleExportExcel = async (dataExport = []) => {
    console.log({ dataExport });
    const workbook = new ExcelJS.Workbook();
    // Ten sheet
    const sheetSummary = workbook.addWorksheet('Giải trừ Vận Tải', {
      properties: { tabColor: { argb: 'FF00FF00' } },
      views: [{ showGridLines: false }],
    });
    // sheetSummary.properties.defaultRowHeight = 18;
    sheetSummary.properties.defaultColWidth = 15;
    sheetSummary.getColumn(1).width = 5;
    sheetSummary.getRow(2).font = {
      name: 'Arial',
      family: 2,
      size: 16,
      bold: true,
    };
    sheetSummary.getRow(2).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getCell('E2').value = 'DANH SÁCH GIẢI TRỪ VẬN TẢI';
    sheetSummary.getRow(3).alignment = { vertical: 'middle', horizontal: 'center' };
    const date = `Từ ${moment(filter?.DateFrom).format('DD/MM/YYYY')} Đến ${moment(
      filter?.DateTo
    ).format('DD/MM/YYYY')}`;
    sheetSummary.getCell('E3').value = date;
    // Add table
    const arrDetail = await dataExport?.map((item) => {
      const dataItem = [
        item?.aid, // sO pHIEU
        item?.folder || 0, // Folder
        item?.contNumber || '', // So Cont
        item?.subnoNumber || '', // So SubNo
        item?.customerName || '', // Khach Hang
        item?.assignDate && new Date(moment(item?.assignDate).format('YYYY-MM-DD')), // Ngày Điều Xe
        item?.dissolveDate && new Date(moment(item?.dissolveDate).format('YYYY-MM-DD')), // Ngày Giải Trừ
        item?.total || 0, // Số Tiền
        item?.createdBy || '', // Người Giải Trừ
        item?.driverMainName || '', // Tài Xe
        item?.isVendor ? 'X' : '', // Xe ngoai
        item?.note || '', // Ghi Chu
      ];
      return dataItem;
    });

    await sheetSummary.addTable({
      name: 'TableSummary',
      ref: 'B5',
      headerRow: true,
      totalsRow: true,
      style: {
        theme: 'TableStyleMedium23', // 23
        // showRowStripes: true,
      },
      columns: [
        { name: 'Số Phiếu', totalsRowLabel: '', filterButton: false },
        { name: 'Folder', totalsRowLabel: '', filterButton: false },
        {
          name: 'Số Cont',
          filterButton: false,
        },
        { name: 'Booking/Bill', totalsRowLabel: '', filterButton: false },
        {
          name: 'Khách Hàng',
          filterButton: false,
        },
        {
          name: 'Ngày Điều Xe',
          filterButton: false,
        },
        {
          name: 'Ngày Giải Trừ',
          filterButton: false,
        },
        {
          name: 'Số Tiền',
          filterButton: false,
          totalsRowFunction: 'sum',
        },
        {
          name: 'Người Giải Trừ',
          filterButton: false,
        },
        {
          name: 'Tài Xế/Nhà Xe',
          filterButton: false,
        },
        {
          name: 'Xe Ngoài',
          filterButton: false,
        },
        {
          name: 'Ghi Chú',
          filterButton: false,
        },
      ],
      rows: arrDetail,
    });
    // ========== STYLES =====================
    sheetSummary.getRow(5).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getColumn(12).alignment = { vertical: 'middle', horizontal: 'center' };
    sheetSummary.getColumn(9).numFmt = '#,##0';
    sheetSummary.getColumn(6).alignment = { wrapText: true };
    sheetSummary.getColumn(13).alignment = { wrapText: true };
    sheetSummary.getColumn(2).width = 10;
    sheetSummary.getColumn(3).width = 10;
    sheetSummary.getColumn(5).width = 30;
    sheetSummary.getColumn(6).width = 25;
    sheetSummary.getColumn(9).width = 15;
    sheetSummary.getColumn(10).width = 20;
    sheetSummary.getColumn(11).width = 20;
    sheetSummary.getColumn(12).width = 10;
    sheetSummary.getColumn(13).width = 30;

    try {
      await workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Danh_Sach_Giai_Tru_Van_Tai_${date}_Xuat_Excel_Luc_${moment().format(
          'DD-MM-YY HH:mm'
        )}.xlsx`; // name excel
        // anchor.download = `${moment().format('HH:mm')}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  const handleGetDataExport = async (query = DEFAULT_QUERIES) => {
    if (!query?.DateFrom || !query?.DateTo || loadingExport) return;

    const dateFrom = moment(query?.DateFrom).format('YYYY-MM-DD');
    const dateTo = moment(query?.DateTo).format('YYYY-MM-DD');

    setLoadingExport(true);
    return ApiServices.getDataExportTicketJob(dateFrom, dateTo)
      .then((res) => {
        if (res?.data?.data) {
          return handleExportExcel(res?.data?.data || []);
        }
      })
      .catch((err) => {
        console.error('get Data Export getDataExportTicketJob: ', err);
      })
      .finally(() => {
        setLoadingExport(false);
      });
  };

  const handleSelectRow = (record, selectedStatus) => {
    let listId = [...selectedMultipleTicket];
    let listRowData = [...selectedMultipleTicketRows];
    if (selectedStatus) {
      // add key Id
      listId.push(record?.id);
      setSelectedMultipleTicket(listId);
      // add key row data
      listRowData.push(record);
      setSelectedMultipleTicketRows(listRowData);
    } else {
      // remove key Id
      const removeSelectedId = listId?.filter((el) => el !== record?.id);
      setSelectedMultipleTicket(removeSelectedId);
      // remove row data
      const removeListRowSelected = listRowData?.filter((el) => el.id !== record?.id);
      setSelectedMultipleTicketRows(removeListRowSelected);
    }
  };
  const handleSelectAll = (selectedNew, _, changeRowsNew) => {
    const listKeyIdOld = [...selectedMultipleTicket];
    const listKeyIdNew = changeRowsNew?.map((item) => item.id);

    let listRowDataOld = [...selectedMultipleTicketRows];
    let listRowDataNew = [...changeRowsNew];

    if (selectedNew) {
      // add all id
      const listIdKeyAdd = listKeyIdOld.concat(listKeyIdNew);
      setSelectedMultipleTicket(listIdKeyAdd);
      // add all data
      const listRowDataAdd = listRowDataOld.concat(listRowDataNew);
      setSelectedMultipleTicketRows(listRowDataAdd);
    } else {
      // remove all id
      const listIdKeyRemove = listKeyIdOld?.filter((el) => !listKeyIdNew.includes(el));
      setSelectedMultipleTicket(listIdKeyRemove);
      // remove all data
      const listRowDataRemove = listRowDataOld?.filter((el) => !listKeyIdNew.includes(el.id));
      setSelectedMultipleTicketRows(listRowDataRemove);
    }
  };

  const renderRowClassName = (record) => {
    if (record?.byVendor) {
      return 'order-from-ONP';
    } else {
      return '';
    }
  };

  return (
    <Wrapper>
      {/* <h3>Danh sách giải trừ vận tải</h3> */}

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Space>
            <RangeDateFilter query={filter} onChange={setFilter} />
            <Badge count={countSelectedTicket}>
              <Button
                danger
                icon={<SwapOutlined />}
                style={{ padding: '0px 20px', marginLeft: '10px' }}
                disabled={countSelectedTicket < 1}
                onClick={handlePrintMultipleTicket}
              >
                Đổi ngày giải trừ
              </Button>
            </Badge>
            <Badge count={countSelectedTicketToAccept}>
              <Button
                type='primary'
                icon={<CheckOutlined />}
                style={{ padding: '0px 20px', marginLeft: '10px' }}
                disabled={countSelectedTicketToAccept < 1}
                onClick={() => handleAcceptTicket(selectedMultipleTicketRows)}
                loading={loadingAccept}
              >
                Xác nhận
              </Button>
            </Badge>
          </Space>

          <div
            style={{
              textAlign: 'end',
              alignItems: 'center',
              marginLeft: '0 auto',
              marginTop: '20px',
            }}
          >
            <Space style={{ marginBottom: '10px' }} align='end'>
              <Button
                type='primary'
                icon={<DownloadOutlined />}
                style={{ padding: '0px 20px', marginRight: '30px', marginLeft: '10px' }}
                disabled={!bookingList?.data?.length}
                onClick={() => handleGetDataExport(filter)}
              >
                Export Excel
              </Button>
              <div style={{ marginRight: '30px' }}>
                <Divider
                  style={{
                    padding: '0px',
                    margin: '0px',
                    color: 'blue',
                    borderColor: 'blue',
                  }}
                >
                  Vận Tải (Tài Xế)
                </Divider>
                <UploadExcelButton
                  loading={loading}
                  onChange={onUploadTicketJobExcel}
                  templateUrl={EXCEL_TICKET_JOB_TEMPLATE_URL}
                  importLabel='Import Excel'
                />
              </div>
              <div style={{ marginRight: '30px' }}>
                <Divider
                  style={{
                    padding: '0px',
                    margin: '0px',
                    color: 'blue',
                    borderColor: 'blue',
                  }}
                >
                  Xe Ngoài
                </Divider>
                <UploadExcelButton
                  loading={loading}
                  onChange={onUploadExcel}
                  templateUrl={EXCEL_TEMPLATE_URL}
                  importLabel='Import Excel'
                />
              </div>
            </Space>
          </div>
        </div>
        <div>
          <CountCont countContBook={countContBook} countContBill={countContBill} />
        </div>
      </div>

      <TableLayoutNew
        rowKey='id'
        headerLength='300px'
        columns={columns}
        data={bookingList?.data || []}
        loading={loading}
        selected={selected}
        selectedParent={selectedMultipleTicket}
        onHandleSelectRow={handleSelectRow}
        onHandleSelectAll={handleSelectAll}
        closeDrawer={() => setSelected(null)}
        pagination={pagination}
        rowClassName={renderRowClassName}
      />
      <Modal
        title='Thông tin chi tiết:'
        open={!!dataRowSelected}
        onCancel={handleCancel}
        footer={null}
        style={{
          top: 20,
        }}
        width='80%'
      >
        <TicketIsAdded dataExpenses={isCreateTicketList} />
        <FormUpdate
          dataCurrent={currentTicketSelected}
          dataInfo={dataRowSelected}
          handleCancel={handleCancel}
          reload={reload}
          onDeleteFee={onDeleteFee}
          onReloadAfterAddNewFee={(dataRow) => onRowSelected(dataRow)}
        />
      </Modal>
      <Modal
        title='Đổi ngày giải trừ:'
        open={showChangeDissolveData}
        onCancel={handleCancelChangeDissolveDate}
        footer={null}
        style={{
          top: 20,
        }}
      >
        <FormChangeDissolveDate
          selectedMultipleTicket={selectedMultipleTicket}
          reloadChangeDissolveDate={reloadChangeDissolveDate}
        />
      </Modal>
    </Wrapper>
  );
};

export { UpdateTicketJob };

import FieldData from './fields-data';

export default (data) => {
  return FieldData({
    ...data,
    billNumber: data.code, //  "TestBillOrder",
    bookingNumber: data.code, //  "ZIMUHCM8088446"
    transitNumber: data.code,
    portOfDischargeCode: data.book_PortOfDischargeCode, //  "60acb885a32e16b886289538",
    locationOfReceiptCode:
      data?.listCont && data?.listCont.length ? data?.locationOfReceiptCode : '', //  "60b055f5896f7718d7d7e7b1",
    locationDepotFromCode:
      data?.listCont && data?.listCont.length ? data?.book_LocationDepotFromCode : '',
    locationOfDeliveryCode:
      data?.listCont && data?.listCont.length ? data?.locationOfDeliveryCode : '', //  "60b1c5b52251856e74c6d887",
    liftingEmptyContRequestDate: data.book_LiftingEmptyContRequestDate
      ? new Date(data.book_LiftingEmptyContRequestDate)
      : null,
    putDownContRequestDateAfter: data.book_PutDownContRequestDateAfter
      ? new Date(data.book_PutDownContRequestDateAfter)
      : null,
    putDownContRequestDateBefore: data.book_PutDownContRequestDateBefore
      ? new Date(data.book_PutDownContRequestDateBefore)
      : null,
    siDateTime: data.book_SIDateTime ? new Date(data.book_SIDateTime) : null,
    cutOffDateTime: data.book_CutOffDateTime ? new Date(data.book_CutOffDateTime) : null,
    lastFreeDayDEM: data.bill_LastFreeDayDEM ? new Date(data.bill_LastFreeDayDEM) : null,
    lastFreeDayDET: data.bill_LastFreeDayDET ? new Date(data.bill_LastFreeDayDET) : null,
    locationDepotReturnCode:
      data?.listCont && data?.listCont.length ? data?.bill_LocationDepotReturnCode : '',
    eta: data.book_ETA ? new Date(data.book_ETA) : null,
    ata: data.book_ATA ? new Date(data.book_ATA) : null,
    etd: data.bill_ETD ? new Date(data.bill_ETD) : null,
    atd: data.bill_ATD ? new Date(data.bill_ATD) : null,
    transitDate: data.transitDate ? new Date(data.transitDate) : null,
    bookReturn: data.bookReturn ? data.bookReturn : false,
  });
};

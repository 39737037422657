import React from 'react';
// PDF
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import { Container } from './Container';
import { Button } from './Button';
import { Card } from './Card';

const options = {
  filename: 'Phiếu_cấp_dầu.pdf',
  method: 'save',
  // default is Resolution.MEDIUM = 3, which should be enough, higher values
  // increases the image quality but also the size of the PDF, so be careful
  // using values higher than 10 when having multiple pages generated, it
  // might cause the page to crash or hang.
  resolution: Resolution.EXTREME,
  page: {
    // margin is in MM, default is Margin.NONE = 0
    margin: Margin.SMALL,
    // default is 'A4'
    format: 'letter',
    // default is 'portrait'
    orientation: 'landscape',
  },
  canvas: {
    // default is 'image/jpeg' for better size performance
    mimeType: 'image/jpeg',
    qualityRatio: 1,
  },
  // Customize any value passed to the jsPDF instance and html2canvas
  // function. You probably will not need this and things can break,
  // so use with caution.
  overrides: {
    // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
    pdf: {
      compress: true,
    },
    // see https://html2canvas.hertzen.com/configuration for more options
    canvas: {
      useCORS: true,
    },
  },
};

// you can also use a function to return the target element besides using React refs
const getTargetElement = () => document.getElementById('container');

const downloadPdf = () => generatePDF(getTargetElement, options);

const ExportOilTicketPDF = ({ dataExportPDF }) => {
  console.log({ dataExportPDF });
  return (
    <Container>
      <div id='container'>
        <Card dataExportPDF={dataExportPDF} imageId={30} title='Using advanced example' />
      </div>
      <Button onClick={downloadPdf}>Download PDF</Button>
    </Container>
  );
};

export { ExportOilTicketPDF };

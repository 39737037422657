import moment from 'moment';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '~commons/constants';

export const KEYS = [
  'orderType', // "Bill",
  'transportType', //  "FCL",deliveryContRequestDate
  'billNumber', //  "TestBillOrder",
  'bookingNumber', //  "ZIMUHCM8088446"
  'customerCode', //  "60b1dc05d33bd61359476dc8",
  'transitNumber',

  'companyId',
  'companyName',
  'vendorId',
  'vendorName',

  'shippingLineCode', //  "60aca5f5a32e16b886289531",
  'vesselAndVoyage', //  "Tàu U&I",
  'locationDepotReturnCode', //  "60b0d0ca2976e69da6cf50ac",
  'cargoTypeCode', //  "60b4b4bfef42f1213378e626",
  'note', //  "Ghi Chú Đơn Hàng Nhập",

  'locationOfReceiptCode', //  "60b055f5896f7718d7d7e7b1",
  'locationOfDeliveryCode', //  "60b1c5b52251856e74c6d887",
  'cargoWeight', //  4500.89,

  // 'shippingLineCode', //  "60aca5f5a32e16b886289531",
  // 'vesselAndVoyage', //  "Chuyến Tàu Hoàng Hôn Updated",
  'portOfDischargeCode', //  "60acb885a32e16b886289538",
  'locationDepotFromCode', //  "60b055f5896f7718d7d7e7b1",
  // 'cargoTypeCode', //  "60b4b48fef42f1213378e624",
  // 'note', //  "Test Ghi Chú Đơn Hàng Xuất updated",
  // 'locationOfReceiptCode', //  "60b0d0ca2976e69da6cf50ac",
  // 'locationOfDeliveryCode', //  "60b1c5b52251856e74c6d887",

  'cargoWeight', //  0,
  // 'isCargo', //  false,
  // 'quantityCont', //  0,

  // 'cargoTypeCode', // "60b4b462ef42f1213378e623",
  // 'note', // "Tạo phiếu chuyển kho Demo nhé",
  // 'locationOfReceiptCode', // "60b1c5b52251856e74c6d887",
  // 'locationOfDeliveryCode', // "60b05ff7896f7718d7d7e7b2",
  // 'cargoWeight', // 7800.63,
  // 'isCargo', // true,
  'orderFiles',
  'bookReturn',
];
export const KEYS_OF_DATE = [
  'liftingEmptyContRequestDate', //  "2021-06-05", Ngày Yêu Cầu Lấy Rỗng
  'eta', //  "2021-06-03", //Ngày Tàu Chạy
  'ata', //Ngày Tàu Chạy Dự Kiến
  'deliveryContRequestDate', //  "2021-05-26",
  'etd', //  "2021-06-01", Ngày Tàu Đến Dự Kiến:
  'atd', //Ngày Tàu Đến
];
export const KEYS_OF_DATE_TIME = [
  'lastFreeDayDEM', //  "2021-06-10T12:39:11.300Z", Ngày Hết Hạn Lưu Cont
  'lastFreeDayDET', //  "2021-06-17T18:39:11.300Z",  Ngày Hết Hạn Trả Rỗng
  'cutOffDateTime', //  "2021-05-30T02:17:35.164Z", Ngày Giờ Cắt Máng
  'siDateTime', //  "2021-05-29T02:17:35.164Z", Ngày Giờ Cắt SI
  'putDownContRequestDateBefore', //  "2021-05-28T02:17:35.164Z", Ngày Yêu Cầu Hạ Cont Trước
  'putDownContRequestDateAfter', //  "2021-05-26T02:17:35.164Z",   Ngày Yêu Cầu Hạ Cont Sau
  'cargoReadyDate', // "2021-06-10T14:14:10.270Z",
  'transitDate', // "2021-06-12",
];

export const KEYS_OF_UPLOAD_FILES = ['orderFiles'];

export default (data) => {
  let result = KEYS.map((item) => {
    return {
      name: [item],
      value: data[item] || null,
      errors: [],
    };
  });

  KEYS_OF_DATE.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] ? moment(data[item], DATE_FORMAT) : null,
    });
  });
  KEYS_OF_DATE_TIME.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] ? moment(data[item], DATE_TIME_FORMAT) : null,
    });
  });

  KEYS_OF_UPLOAD_FILES.forEach((item) => {
    result.push({
      name: [item],
      value: data[item] || [],
      errors: [],
    });
  });

  return result;
};
